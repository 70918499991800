<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">经营分析</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-district-choose ref="district" label="请选择省" :province.sync="pageParam.params.provinceCode" :city.sync="pageParam.params.cityCode" provinceAndCity></le-input-district-choose>
                <!-- <le-date-range 
                    ref="dateRange" 
                    label="站点开通时间"  
                    :minDate.sync="pageParam.params.stationStartTime" 
                    :maxDate.sync="pageParam.params.stationEndTime" /> -->
                <!-- <le-select-local-search label="统计范围" v-model="pageParam.params.companyType" :options="optionsStatIncome" /> -->
                <le-date-range 
                    ref="dateRangeSearch" 
                    label="搜索时间"  
                    :pickerOptions="pickerOptions" 
                    :minDate.sync="pageParam.params.searchStartTime" 
                    :maxDate.sync="pageParam.params.searchEndTime" />
            </le-search-form>
            <le-pagview ref="businessAnalysisProvins" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.usinessAnalysisProvinsList" :pageSizeDefault='10'>
                <el-table ref="usinessAnalysisProvinsList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="province" label="省份" min-width="110">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="toBusinessAnalysisInfo(row)">{{ row.province?row.province:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="总营业额" min-width="168">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs a-w-100" style="align-items: flex-start !important;">
                                <span>总营收：￥{{ util.numFormat(row.totalAmount) }}</span>
                                <span>退款：￥{{ util.numFormat(row.totalRefundAmount) }}</span>
                                <span>实收：￥{{ util.numFormat(row.totalAmount - row.totalRefundAmount) }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="companyName" label="累计收益(元)" min-width="152">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start !important">
                                <span>总收益：￥{{ row.allIncomeAmount?(row.allIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>套餐收益：￥{{ row.allMonthCardIncomeAmount?(row.allMonthCardIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电桩收益：￥{{ row.allChargingIncomeAmount?(row.allChargingIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电柜收益：￥{{ row.allBoxIncomeAmount?(row.allBoxIncomeAmount/100).toFixed(2):0 }}</span>
                                <span>充电舱收益：￥{{ util.numFormat(row.allRoomIncomeAmount) }}</span>
                                <span>毛豆消耗：￥{{ (row.allPoints || 0) - (row.allPointsRefund || 0) }}</span>
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="companyName" label="" min-width="220">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>搜索时间内收益</span>
                                <span class="a-fs-12 a-c-999"></span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs a-w-100" style="align-items: flex-start !important;">
                                <span>总收益：￥{{ util.numFormat(row.timeAmount) }}</span>
                                <span>车库套餐收益：￥{{ util.numFormat(row.carParkIncomeAmount) }}</span>
                                <span>充电桩套餐收益：￥{{ util.numFormat(row.chargeMonthCardIncomeAmount) }}</span>
                                <span>充电柜套餐收益：￥{{ util.numFormat(row.boxMonthCardIncomeAmount) }}</span>
                                <span>充电桩收益：￥{{ util.numFormat(row.chargeIncomeAmount) }}</span>
                                <span>充电柜收益：￥{{ util.numFormat(row.boxIncomeAmount) }}</span>
                                <span>充电舱收益：￥{{ util.numFormat(row.roomIncomeAmount) }}</span>
                                <!-- <span>毛豆消耗：￥{{ (row.points || 0) - (row.pointsRefund || 0) }}</span> -->
                                <!-- <span>水机收益：￥{{ util.numFormat(row.waterIncomeAmount) }}</span> -->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="历史单插槽收益" min-width="152">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.perSlotAmountHis) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="" min-width="152">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>单插槽收益</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.perSlotAmountTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="单插槽使用率" min-width="152">
                        <template slot-scope="{ row }">
                            <span>{{ row.slotUsingRationHis || 0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="单插槽使用率" min-width="152">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>单插槽使用率</span>
                                <span class="a-fs-12 a-c-999">搜索时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.slotUsingRationTime || 0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="设备(在线/总数)" min-width="152">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs">
                                <span class="block">在线：{{ row.onlineDeviceNum }}（{{ row.onlineSlotNum }}）</span>
                                <span class="block">总数：{{ row.totalDeviceNum }}（{{ row.totalSlotNum }}）</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="正在充电插槽数" min-width="152">
                        <template slot-scope="{ row }">
                            <span>{{ row.usingSlotNum?row.usingSlotNum:0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="站点数量/项目数量" min-width="152">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationNumHis?row.stationNumHis:0 }}/{{ row.incomeNumHis?row.incomeNumHis:0 }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="name" label="站点数量/项目数量" min-width="152">
                        <template slot="header">
                            <div class="a-flex-cfsfs">
                                <span>站点数量/项目数量</span>
                                <span class="a-fs-12 a-c-999">站点开通时间内</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.stationNumTime?row.stationNumTime:0 }}/{{ row.incomeNumTime?row.incomeNumTime:0 }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toBusinessAnalysisInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal">条结果</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getAnalysisCityPage,
                    method: "post",
                    params: {
                        areaCode: '',
                        cityCode: '',
                        provinceCode: '',
                        searchEndTime: this.$getDay.getTodayBeforeDays(1),//搜索时间结束
                        searchStartTime: this.$getDay.getTodayBeforeDays(31),// 搜索时间开始
                        companyType: '',//统计范围
                        stationEndTime: '',
                        stationStartTime: '',//
                        searchProvinceType: 1,//查询到的维度,1省,2市,3区
                    },
                    freshCtrl: 1,
                },
                pickerOptions: {
                    disabledDate: (time) => {
                        return time.getTime() > Date.now() - (24 * 60 * 60 * 1000)
                    },
                },
                optionsStatIncome:[],
                fileUrl: ''
            }
        },
        created () {
            this.$getDic('companyType','select').then(res=>{ 
                this.optionsStatIncome = res; 
                this.optionsStatIncome.unshift({
                    label: '全部',
                    value: ''
                })
            })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    areaCode: '',
                    cityCode: '',
                    provinceCode: '',
                    searchEndTime: this.$getDay.getTodayBeforeDays(1),//搜索时间结束
                    searchStartTime: this.$getDay.getTodayBeforeDays(31),// 搜索时间开始
                    companyType: '',//统计范围
                    stationEndTime: '',
                    stationStartTime: '',//
                    searchProvinceType: 1,
                };
                this.$refs['dateRange'].value1 = ['','']
                this.$refs['dateRangeSearch'].value1 = [this.$getDay.getTodayBeforeDays(31),this.$getDay.getTodayBeforeDays(1)]
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['businessAnalysisProvins'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            toBusinessAnalysisInfo (datas) {
                this.$router.push({
                    path: '/businessAnalysis/businessAnalysis-info',
                    query: {
                        id: datas.provinceCode,
                        title: datas.province,
                        type: 'province',
                        stationStartTime: this.pageParam.params.stationStartTime,
                        stationEndTime: this.pageParam.params.stationEndTime,
                        companyType: this.pageParam.params.companyType,
                    }
                })
            },
			
            exportfile () {
                this.$exfile({
                    code: 15,
                    fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                    startTime: this.pageParam.params.searchStartTime,
                    endTime: this.pageParam.params.searchEndTime,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>